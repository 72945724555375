import React from "react";
import { Link } from 'react-scroll';
import { MdOutlineMenu } from 'react-icons/md';

import "../App.css";

function Navbar() {

    const setResponsive = () => {
        var x = document.getElementById("topNavRight");
        if (x.className === "navbarRight") {
            x.className += " responsive";
        } else {
            x.className = "navbarRight";
        }
    }
    return(
    <div className="Navbar">
        <div className="navbarMain">
            <Link
                to="main"
                spy={true}
                smooth={true}
                duration={1000}
                offset={-100}
                className="navbarLink"
            >
            Lewis Boylan
            </Link>
        </div>

        <div id="topNavRight" className="navbarRight">
            <nav>
                <Link
                    to="gallery"
                    spy={true}
                    smooth={true}
                    duration={1000}
                    className="navbarLink"
                    offset={50}
                >
                    Gallery
                </Link>
                <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={1000}
                    className="navbarLink"
                    offset={50}
                >
                    Contact
                </Link>
                <a href="#" className="icon" onClick={setResponsive}>
                    <MdOutlineMenu />
                </a>
            </nav>
        </div>
    </div>
    )
}

export default Navbar;