import React, { useEffect } from 'react';
import './App.css';
import { Link, Element } from 'react-scroll';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import Gallery from 'react-grid-gallery';

import Navbar from "./components/Navbar.js";
import MainText from "./components/MainText.js";
import ContactMe from "./components/ContactMe.js";

const photos = []

function App() {
    const [ loading, setLoading ] = React.useState(true);

    useEffect(() => {
        getImages();
    }, []);

    const getImages = () => {
        fetch(
            "https://api.lewisboylanart.com")
            .then((res) => res.json())
            .then((json) => {
                const src = JSON.parse(json.body);
                Object.entries(src).forEach((entry) => {
                    var imgPath = "https://images.lewisboylanart.com/" + entry[1].name
                    var img = {
                        src: imgPath,
                        thumbnail: imgPath,
                        caption: entry[1].name,
                        thumbnailWidth: 350,
                        thumbnailHeight: 250
                    }
                    photos.push(img);
                })
                setLoading(false);
        })
    }

    if (loading) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <div className='App'>
            <Element id="top" name="top">
                <Navbar />
            </Element>

            <div className="mainContent">
                <Element id="main" name="Main">
                    <MainText />
                </Element>

                <Element id="gallery" name="gallery">
                    <div className='heading newCategory gallery'>
                        My <span className="emphasis">Portfolio</span>:
                        <Gallery images={photos} enableImageSelection={false} /> 
                    </div>
                </Element>

                <Element id="contact" name="contact">
                    <ContactMe />
                </Element>
            </div>

            <Link
			to="top"
			spy={true}
			smooth={true}
			duration={1000}
			className="navbarLink"
		    >
    			<BsFillArrowUpCircleFill className="floatIcon" />
		    </Link>
        </div>
    );
}

export default App;
