import React from "react";
import {SocialMediaIconsReact} from 'social-media-icons-react';
import "../App.css";

function MainText() {

    return (
        <div>
            <div className="heading newCategory">
                Contact me
            </div>
            <p>If you like my art and would like for me to work on a commission, get in touch and we can discuss your needs.</p>

            <p>You can reach me here:</p>
            <SocialMediaIconsReact borderColor="rgba(53,46,46,0.25)" borderWidth="5" borderStyle="ridge" icon="twitter" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(26,166,233,1)" iconSize="6" roundness="50%" url="https://twitter.com/" size="50" />

            <SocialMediaIconsReact borderColor="rgba(53,46,46,0.25)" borderWidth="5" borderStyle="ridge" icon="facebook" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(37,127,242,1)" iconSize="6" roundness="50%" url="https://facebook.com/" size="50" />

            <SocialMediaIconsReact borderColor="rgba(53,46,46,0.25)" borderWidth="5" borderStyle="ridge" icon="mail" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(107,117,129,1)" iconSize="6" roundness="50%" url="mailto:lewis@lewisboylanart.com" size="50" />

        </div>
    )
}

export default MainText;