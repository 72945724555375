import React from "react";

import "../App.css";

function MainText() {

    return (
        <div>
            <div className="heading">
                Hello, I'm <span className="emphasis">Lewis.</span> I'm an <span className="emphasis">artist.</span>
            </div>
            <p>I'm a digital artist based in Scotland, UK trained as an Illustrator and Graphic Designer at Ayrshire College & Glasgow Caledonia. My art style can be seen in the gallery below, but I particularly enjoy making portraits, pixel art and environment scenes.
            </p>
            
            <p>I can do realistic or stylised approaches to meet your needs. I'm available for commissions, just get in touch.</p>

        </div>
    )
}

export default MainText;